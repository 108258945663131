<template>
<div class="reset owf-h">
    <div class="reset-title">
        <h1 >Відновлення паролю</h1>
        <p >Ми допоможемо Вам відновити доступ до Вашого профілю</p>
    </div>
    <div class="flex justify-center">
        <div class="login-card">
            <div class="password-input" >
                <el-input placeholder="Новий пароль" class="with-icon" v-model="input" :type="password_show1?'text':'password'"></el-input>
                <div class="show-password">
                    <img :src="password_show1? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show1=!password_show1" alt="">
                </div>
            </div>
            <div class="password-input" >
                <el-input placeholder="Повторити пароль" class="with-icon" v-model="input" :type="password_show2?'text':'password'"></el-input>
                <div class="show-password">
                    <img :src="password_show2? require('@/assets/icons/openeye.svg'):require('@/assets/icons/overlineye.svg')" @click="password_show2=!password_show2" alt="">
                </div>
            </div>
            <div class="flex login-button">
                <button class="button" @click="$router.push('/me')" >Продовжити</button>
            </div>
            <div class="reset-link">
                <router-link to="/login" tag="a" >
                    Повернутись до авторизації
                </router-link>
            </div>
        </div>
    </div>
    <div class="login-bottom-text phone"  data-aos="fade-bottom">
        <p>Політика конфіденційності</p>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input: '',
            password_show1: false,
            password_show2: false,
        }
    },
}
</script> 

<style lang="scss">
.reset {
    padding: 200px 120px 100px 120px;
    background: var(--white-bg);
}

.reset-title {
    margin-bottom: 40px;

    h1 {
        font-family: Stem-Medium;
        font-size: 51px;
        line-height: 60px;
        text-align: center;

        color: var(--other-black);
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        margin-top: 20px;

        color: var(--white-black);
    }
}

.login-card {
    width: 100%;
    max-width: 600px;
    padding: 60px;
    box-shadow: 0px 6px 16px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px rgba(0, 72, 153, 0.03);
    background: var(--white-bg);
    border-radius: 10px;
    margin-top: 60px;

    .el-input__inner {
        border: none;
        border-bottom: 1.5px solid #DDDFE2;
        padding: 20px;
        height: auto;
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: var(--white-black);
        border-radius: 0;

    }

    .black-check {
        margin-top: 20px;
        display: flex;
        align-items: center;

        p {
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            margin-left: 10px;
            color: var(--white-black);
        }
    }

    .login-button {
        margin-top: 60px;
        display: flex;
        justify-content: center;
    }

    .reset-link {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        a {
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem;
            display: flex;
            align-items: center;
            text-decoration-line: underline;

            color: var(--other-black);
        }
    }

    .password-input {
        position: relative;

        .show-password {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                cursor: pointer;
            }
        }

    }
}

.login-bottom-text {

    padding: 40px 20px 0px 20px;

    p {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem;
        color: var(--white-black);
        text-align: center;
    }
}

@media screen and(max-width:1130px) {

    .reset {
        padding: 200px 20px 40px 20px;
    }

}

@media screen and(max-width:768px) {

    .reset {
        padding: 150px 0px 40px 0px;
        background: var(--bg-top);
    }

    .login-card {
        max-width: 470px;
        margin-top: 40px;
        padding: 40px 20px;

        .el-input__inner {
            padding: 18px;
            font-size: 1rem;
            line-height: 1.125rem;

        }

        .reset-link {
            a {
                font-size: 0.875rem;
                line-height: 1rem;
            }
        }
    }

    .reset-title {
        padding: 0px 20px;
        margin-bottom: 40px;

        h1 {
            font-size: 24px;
            line-height: 28px;
        }

        p {
            font-size: 0.875rem;
            line-height: 1rem;
            margin-top: 10px;
        }
    }

    .login-bottom-text {

        padding: 40px 20px 0px 20px;

        p {
            font-family: Stem-Regular;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--white-black);
            text-align: center;
        }
    }

}
</style>
